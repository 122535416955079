@import "./utils/modern-normalize";
@import "./utils/syntax";
@import "./utils/fonts";
@import "./utils/colors";
@import "./objects/header";
@import "./pages/list";
@import "./pages/post";
@import "./pages/welcome";
@import "./pages/projects";

body {
  @include theme("background-color", $background-color);
  margin: 1rem 4rem;
  font-family: "Atkinson Hyperlegible", serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.content {
  @include theme("color", $text-color);
  max-width: 700px;
  margin: 2rem auto 3rem auto;
  font-family: "Atkinson Hyperlegible", serif;
  font-weight: 400;
  line-height: 1.5em;
  font-style: normal;
  font-size: 1.2rem;
  font-stretch: 100%;
}

.impressum {
  margin-top: 4rem;
}

@media (max-width: 30rem) {
  body {
    margin: 1rem 1rem 3rem 1rem;
  }
}

